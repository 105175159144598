import classNames from 'classnames';

import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Image } from '@features/shared/components/image';
import { Col, Container, Row } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';
import { ILink } from '@features/shared/contentful/types/ILink';
import { AlignmentOption } from '@features/shared/types/enums/AlignmentOption';

import { SectionButton } from 'src/features/shared/components/sections/section-button/SectionButton';
import {
  ButtonDisplayAs,
  SectionBackgroundColor,
} from 'src/features/shared/utils/constants';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import styles from './ImageSection.module.scss';
import { EntryFields } from 'contentful';
import { SectionHeader } from '../../section-header';

export interface IImageSectionProps {
  image: IImage;
  displayAs: ButtonDisplayAs;
  alignImage: AlignmentOption;
  title?: string;
  link?: ILink;
  description?: EntryFields.RichText;
  anchorLinkTitle?: string;
  backgroundColor: SectionBackgroundColor | null;
  stickToTop?: boolean;
  stickToBottom?: boolean;
  secondaryLink?: ILink;
  secondaryLinkLabel?: string;
  secondaryLinkDisplayAs?: ButtonDisplayAs;
}

export const ImageSection = ({
  image,
  displayAs,
  alignImage,
  title,
  link,
  description,
  anchorLinkTitle = '',
  backgroundColor,
  stickToTop,
  stickToBottom,
  secondaryLink,
  secondaryLinkDisplayAs,
}: IImageSectionProps) => {
  const { renderSectionDescription } = useRichTextRenderer();
  const { currentBreakpoint, breakpoints } = useBreakpoints();

  const isAlignedTop =
    alignImage === AlignmentOption.Top ||
    currentBreakpoint === breakpoints.sm.name;
  const isAlignedRight = alignImage === AlignmentOption.Right;
  const isAlignedLeft = alignImage === AlignmentOption.Left;

  return (
    <Section hasNoBottomMargin={stickToBottom} hasImage>
      <Anchor title={anchorLinkTitle} />

      <Container
        backgroundColor={backgroundColor}
        stickToBottom={stickToBottom}
        stickToTop={stickToTop}
      >
        <Row>
          <Col
            columns={`${isAlignedTop ? 'md:8' : 'md:12'}`}
            className={classNames({ [styles.isCentered]: isAlignedTop })}
          >
            <Row reversed={isAlignedRight} className={styles.row}>
              <Col
                className={classNames(
                  styles.imageCol,
                  isAlignedTop && styles.isCentered,
                )}
                columns={isAlignedTop ? '' : 'md:6'}
              >
                <div className={styles.columnContainer}>
                  <Image className={styles.image} image={image} />
                </div>
              </Col>

              <Col
                columns={isAlignedTop ? '' : 'md:6'}
                className={classNames(styles.content, {
                  [styles.isLeft]: isAlignedLeft,
                  [styles.isRight]: isAlignedRight,
                  [styles.isCentered]: isAlignedTop,
                })}
              >
                <div className={styles.columnContainer}>
                  <SectionHeader title={title} />
                  {description
                    ? renderSectionDescription(description, styles.description)
                    : null}
                  <div className={styles.buttonsWrapper}>
                    {link ? (
                      <SectionButton
                        link={link}
                        label={link.label}
                        eventName="click_cta_imagesection"
                        displayAs={displayAs || ButtonDisplayAs.PrimaryButton}
                      />
                    ) : null}
                    {secondaryLink ? (
                      <SectionButton
                        link={secondaryLink}
                        label={secondaryLink.label}
                        eventName="click_secondary_cta_imagesection"
                        displayAs={
                          secondaryLinkDisplayAs ||
                          ButtonDisplayAs.PrimaryButton
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};
